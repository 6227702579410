import { createApp, ref } from "vue";
import App from "./App.vue";

import ItemButton from "/source/js/vue/components/elements/buttons/ItemButton.vue";
import TermsItem from "/source/js/vue/components/common/TermsItem.vue";

document.querySelectorAll('#disclaimer').forEach(elem => {
  const app1 = createApp({
    App,
    setup() {
      const show_legals = ref(null);
      return {
        show_legals,
      };
    },
    mounted() {
      this.show_legals = false;
    },
  });

  app1.component("ItemButton", ItemButton).mount(elem);
})
document.querySelectorAll('#corporate-clients').forEach(elem => {
  const app2 = createApp({
    App,
    setup() {
      const agreement = ref(null);
      return {
        agreement,
      };
    },
    mounted() {
      this.agreement = true;
    },
  });

  app2.component("ItemButton", ItemButton).mount(elem);
})

document.querySelectorAll('#about').forEach(elem => {
  const app3 = createApp({
    App,
  });

  app3.component("TermsItem", TermsItem).mount(elem);
})