<script>
import { directive } from 'vue3-click-away';
import { isEqual } from 'lodash';
import SelectOption from '/source/js/vue/components/common/SelectOption.vue';
import SvgArrowDown from '/source/js/vue/components/icons/ArrowDown.vue';

export default {
  components: {
    SelectOption,
    SvgArrowDown,
  },
  directives: {
    ClickAway: directive,
  },
  props: {
    /**
     * Значение v-model родителя
     *
     * @type {String, Number}
     */
    modelValue: {
      type: [Array, Object],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'name',
    },
    placeholder: String,
    disabled: Boolean,
    multiple: Boolean,
    theme: {
      type: String,
      default: '',
    },
    classesOption: {
      type: String,
      default: '',
    },
    classesSpan: {
      type: String,
      default: '',
    },
    classesSpancheck: {
      type: String,
      default: '',
    },
    inscription: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isOpen: false,
      mainValue: [],
      inputValue: null,
      allOptions: null,
      search: '',
      selected: null,
      preventParentClick: false,
    };
  },
  computed: {
    legend() {
      if (this.multiple) {
        if (this.mainValue?.length === 0) {
          return this.placeholder;
        }
        if (this.mainValue.length === 1) {
          return this.mainValue[0][this.label];
        }
        return `Выбрано ${this.mainValue.length}`;
      }

      return this.mainValue ? this.mainValue[this.label] : this.placeholder;
    },
  },
  watch: {
    modelValue(newValue) {
      if (!isEqual(newValue, this.mainValue)) {
        if (this.multiple) {
          this.mainValue = [...newValue];
        } else {
          this.mainValue = newValue;
        }
      }
    },
    mainValue() {
      this.$emit('update:modelValue', this.mainValue);
    },
    options(newValue, oldValue) {
      if (this.options) {
        this.allOptions = this.options;
      }

      if (!isEqual(newValue, oldValue)) {
        if (this.multiple) {
          const value = this.mainValue.filter(option => this.options.find(op => op[this.label] === option[this.label]));

          if (!isEqual(value, this.mainValue)) {
            this.mainValue = newValue;
          }
        } else if (this.mainValue?.length > 0) {
          if (!this.options.find(option => option[this.label] === this.mainValue[this.label])) {
            this.mainValue = null;
          }
        }
      }
    },
    inputValue() {
      let filteredOptions = [];

      if (this.filter) {
        filteredOptions = this.options.filter(option =>
          option.name.toLowerCase().includes(this.inputValue.toLowerCase())
        );
      }

      this.groupOptions();

      this.allOptions = filteredOptions;
    },
  },
  created() {
    if (this.modelValue) {
      if (this.multiple) {
        this.modelValue.forEach(this.select);
      } else {
        this.select(this.modelValue);
      }
    }

    if (this.options) {
      this.allOptions = this.options;
    }
  },
  methods: {
    away() {
      if (this.isOpen) {
        this.isOpen = false;
      }
    },
    select(option) {
      if (this.multiple) {
        this.mainValue = [...this.mainValue, option];
        this.isOpen = true;
        this.preventParentClick = true;
      } else {
        this.mainValue = option;
      }
    },
    deselect(option) {
      if (this.multiple) {
        this.mainValue = this.mainValue.filter(op => op[this.label] !== option[this.label]);
        this.isOpen = true;
        this.preventParentClick = true;
      } else {
        this.mainValue = null;
      }
    },
    onOptionClick(option) {
      if (this.isOptionSelected(option)) {
        this.deselect(option);
      } else {
        this.select(option);
      }
    },
    isOptionSelected(option) {
      return this.multiple
        ? this.mainValue.find(op => op[this.label] === option[this.label])
        : this.mainValue && this.mainValue[this.label] === option[this.label];
    },
    selectClickSettings() {
      if (!this.preventParentClick && this.multiple) {
        this.isOpen = !this.isOpen;
      } else if (this.preventParentClick && this.multiple) {
        this.isOpen = true;
        this.preventParentClick = !this.preventParentClick;
      } else if (this.preventParentClick) {
        this.isOpen = true;
        this.preventParentClick = !this.preventParentClick;
      } else {
        this.isOpen = !this.isOpen;
      }
    },
  },
};
</script>

<template>
  <div
    v-click-away="away"
    class="relative w-full leading-none cursor-pointer py-2 px-5 border border-gray-200 rounded-md "
    :class="[{
        'pointer-events-none bg-white opacity-60': disabled,
        'border-red': error
    }, theme ? 'bg-transparent text-white text-xl py-0 border-none' : 'bg-white']"
    @click="selectClickSettings"
  >
    <div
      v-html="inscription"
      class="absolute -top-3 left-2 text-sm px-1 text-gray-600 bg-white"
    ></div>
    <div
      class="flex items-center justify-between w-full"
      :class="{
          'max-width-select': multiple,
        }"
    >
      <label
        v-html="legend || placeholder"
        :class="['transform truncate text-ellipsis overflow-hidden pr-5 cursor-pointer  opacity-70 br-hidden', theme ? 'text-white' : 'text-black']"
      >
      </label>
      <svg-arrow-down
        class="absolute right-3 top-1/2 ml-4 w-3 h-1.5 transform origin-center -translate-y-1/2 duration-300 text-gray-600 stroke-current"
        :class="{
            'rotate-180': isOpen,
          }"
      />
    </div>
    <div
      class="options max-h-[315px] absolute z-40 left-0 top-full flex-col shadow-lg overflow-scroll overflow-x-hidden mt-1 rounded-lg "
      :class="[isOpen ? 'flex' : 'hidden', theme ? 'bg-gradient-to-r from-[#686A6F] to-[#313031]' : 'bg-white border-gray-200']"
    >
      <select-option
        v-for="option in allOptions"
        :key="option[label]"
        :option="option[label]"
        :is-option-selected="!isOptionSelected(option)"
        :multiple="multiple"
        :theme="theme"
        @click="onOptionClick(option)"
        class="hover:bg-gray-100"
      />
      <div
        v-if="options?.length === 0"
        class="py-4 px-5 text-gray-400 cursor-not-allowed"
      >
        <slot name="no-options">Нет данных</slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.abs-center {
  @apply absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

.options {
  width: calc(100% + 2px);
  margin-left: -1px;
}

/* W3C standard
   сейчас только для Firefox */
.options {
  scrollbar-width: thin;
  scrollbar-color: #a50034 transparent;
}

/* для Chrome/Edge/Safari */
.options::-webkit-scrollbar-track {
  background: transparent;
}
.options::-webkit-scrollbar-thumb {
  background-color: #a50034;
}

.input-width {
  width: calc(100% - 55px);
}
</style>
