export default [
  {
  "EXEED": {
    models: [
        {id: 1, name: "LX", image: "White"},
        {id: 2, name: "TXL", image: "Black"},
        {id: 3, name: "VX", image: "901E2E"}
      ]
    },
  }
]