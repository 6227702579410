export default [
  {
    id: "1",
    brand: "EXEED",
    model: "LX",
    complectation: "Prestige 1.5T 147 CVT",
    image: "White",
    price: "2794900",
  },
  {
    id: "2",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Black",
    price: "3679900",
  },
  {
    id: "3",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Black",
    price: "3679900",
  },
  {
    id: "4",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Gray",
    price: "3679900",
  },
  {
    id: "5",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Havana Gray",
    price: "3679900",
  },
  {
    id: "6",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Havana Gray",
    price: "3679900",
  },
  {
    id: "7",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Purple",
    price: "3679900",
  },
  {
    id: "8",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Purple",
    price: "3679900",
  },
  {
    id: "9",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Purple",
    price: "3679900",
  },
  {
    id: "10",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Purple",
    price: "3679900",
  },
  {
    id: "11",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship SE",
    image: "Purple",
    price: "4119900",
  },
  {
    id: "12",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship SE",
    image: "Purple",
    price: "4119900",
  },
  {
    id: "13",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship SE",
    image: "Purple",
    price: "4119900",
  },
  {
    id: "14",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "White",
    price: "3679900",
  },
  {
    id: "15",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "White",
    price: "3679900",
  },
  {
    id: "16",
    brand: "EXEED",
    model: "TXL",
    complectation: "Luxury",
    image: "White",
    price: "3456900",
  },
  {
    id: "17",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "White",
    price: "3679900",
  },
  {
    id: "18",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "White",
    price: "3679900",
  },
  {
    id: "19",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "White",
    price: "3679900",
  },
  {
    id: "20",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "White",
    price: "3679900",
  },
  {
    id: "21",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "White",
    price: "3679900",
  },
  {
    id: "22",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "White",
    price: "3679900",
  },
  {
    id: "23",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "White",
    price: "3679900",
  },
  {
    id: "24",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Bluish Gray",
    price: "3679900",
  },
  {
    id: "25",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Havana Gray",
    price: "3679900",
  },
  {
    id: "26",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Havana Gray",
    price: "3679900",
  },
  {
    id: "27",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Havana Gray",
    price: "3679900",
  },
  {
    id: "28",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Purple",
    price: "3679900",
  },
  {
    id: "29",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Purple",
    price: "3679900",
  },
  {
    id: "30",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Purple",
    price: "3679900",
  },
  {
    id: "31",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Purple",
    price: "3679900",
  },
  {
    id: "32",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Black",
    price: "3679900",
  },
  {
    id: "33",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Black",
    price: "3679900",
  },
  {
    id: "34",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Black",
    price: "3679900",
  },
  {
    id: "35",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Black",
    price: "3679900",
  },
  {
    id: "36",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Black",
    price: "3679900",
  },
  {
    id: "37",
    brand: "EXEED",
    model: "TXL",
    complectation: "Flagship",
    image: "Black",
    price: "3679900",
  },
  {
    id: "38",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Black",
    price: "4648900",
  },
  {
    id: "39",
    brand: "EXEED",
    model: "VX",
    complectation: "President LE",
    image: "Black",
    price: "5018900",
  },
  {
    id: "40",
    brand: "EXEED",
    model: "VX",
    complectation: "Luxury",
    image: "Black",
    price: "4322000",
  },
  {
    id: "41",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Blue",
    price: "4648900",
  },
  {
    id: "42",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Blue",
    price: "4648900",
  },
  {
    id: "43",
    brand: "EXEED",
    model: "VX",
    complectation: "President LE",
    image: "Green",
    price: "5018900",
  },
  {
    id: "44",
    brand: "EXEED",
    model: "VX",
    complectation: "President LE",
    image: "Green",
    price: "5018900",
  },
  {
    id: "45",
    brand: "EXEED",
    model: "VX",
    complectation: "President LE",
    image: "Green",
    price: "5018900",
  },
  {
    id: "46",
    brand: "EXEED",
    model: "VX",
    complectation: "President LE",
    image: "Havana Gray",
    price: "5018900",
  },
  {
    id: "47",
    brand: "EXEED",
    model: "VX",
    complectation: "Luxury",
    image: "Havana Gray",
    price: "4322000",
  },
  {
    id: "48",
    brand: "EXEED",
    model: "VX",
    complectation: "President LE",
    image: "Havana Gray",
    price: "5018900",
  },
  {
    id: "49",
    brand: "EXEED",
    model: "VX",
    complectation: "President LE",
    image: "Havana Gray",
    price: "5018900",
  },
  {
    id: "50",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Havana Gray",
    price: "4648900",
  },
  {
    id: "51",
    brand: "EXEED",
    model: "VX",
    complectation: "President LE",
    image: "Havana Gray",
    price: "5018900",
  },
  {
    id: "52",
    brand: "EXEED",
    model: "VX",
    complectation: "President LE",
    image: "Havana Gray",
    price: "5018900",
  },
  {
    id: "53",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Purple",
    price: "4648900",
  },
  {
    id: "54",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Purple",
    price: "4648900",
  },
  {
    id: "55",
    brand: "EXEED",
    model: "VX",
    complectation: "Luxury",
    image: "Purple",
    price: "4322000",
  },
  {
    id: "56",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Purple",
    price: "4648900",
  },
  {
    id: "57",
    brand: "EXEED",
    model: "VX",
    complectation: "Luxury",
    image: "White",
    price: "4322000",
  },
  {
    id: "58",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "White",
    price: "4648900",
  },
  {
    id: "59",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "White",
    price: "4648900",
  },
  {
    id: "60",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Havana Gray",
    price: "4648900",
  },
  {
    id: "61",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Havana Gray",
    price: "4648900",
  },
  {
    id: "62",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Havana Gray",
    price: "4648900",
  },
  {
    id: "63",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Havana Gray",
    price: "4648900",
  },
  {
    id: "64",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Havana Gray",
    price: "4648900",
  },
  {
    id: "65",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Black",
    price: "4648900",
  },
  {
    id: "66",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Black",
    price: "4648900",
  },
  {
    id: "67",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Black",
    price: "4648900",
  },
  {
    id: "68",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Black",
    price: "4648900",
  },
  {
    id: "69",
    brand: "EXEED",
    model: "VX",
    complectation: "President",
    image: "Black",
    price: "4648900",
  },
];
