<script>
import VSelect from '/source/js/vue/components/common/VSelect.vue'
export default {
  props:['brands'],
  components: {
    VSelect,
  },
  data() {
   return {
    brand: null,
   }
  }
}
</script>

<template>
  <v-select
    :options="brands"
    v-model="brand"
    class="container relative rounded-md py-2 px-5" >
  </v-select>
</template>

<style lang="">

</style>
