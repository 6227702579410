<template>
  <div js-popup=""
    class="flex flex-row items-center border border-black border-solid  px-1 justify-center gap-2 py-2 text-xs w-full uppercase after:block after:border-r after:border-b after:border-beigeRed after:w-3 after:h-3 after:-rotate-45"
  >
    <slot name="text"></slot>
  </div>
</template>
<script>

export default {
  name: "ItemButton"
};
</script>
